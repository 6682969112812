<template>
  <v-container fluid fill-height class="pa-0 ma-0">
    <div class="login-backgroud-img"></div>
    <v-card id="login-card" class="elevation-1 login-card body-2">
      <v-toolbar color="white" dark flat height="80">
        <v-img src="@/assets/logo.webp" max-width="170" class="mx-auto" asspect-rate></v-img>
      </v-toolbar>
      <v-card-text class="mt-3 pb-1">
        <v-form ref="loginForm">
          <v-text-field outlined dense name="login" label="メールアドレス" type="text" v-model="model.email" :rules="emailRules"
            required class="my-1" @keydown.enter="login"></v-text-field>
          <v-text-field name="password" label="パスワード" :append-icon="showPassword ? svgEye : svgEyeOff"
            @click:append="showPassword = !showPassword" id="password" :type="showPassword ? 'text' : 'password'" outlined
            dense v-model="model.password" :rules="passwordRules" required class="my-1"
            @keydown.enter="login"></v-text-field>
        </v-form>
        <v-alert v-if="isNoMatch" dense color="red accent-1" class="white--text caption">
          メールアドレス,パスワードが正しくありません
        </v-alert>
        <v-alert v-else-if="isAccountLocked" dense color="red accent-1" class="white--text caption">
          アカウントがロックされています。30分待つか、メールを確認して下さい。
        </v-alert>
        <router-link to="/reset_password" class="primary--text">
          パスワードを忘れた方
        </router-link>
      </v-card-text>
      <div class="login-btn mt-4 px-4 pb-4">
        <v-btn block dark color="primary" @click="login" :loading="loading">
          <v-icon small>{{ svgLockOpen }}</v-icon>
          サインイン
        </v-btn>
      </div>
      <div class="pa-4">
        <p style="border-top: 1px solid gray;"></p>
        <p class="text-center text--secondary">初めてご利用の方</p>
        <v-row justify="center">
          <router-link to="/create_account" class="primary--text mb-4">
            アカウントを作成する
          </router-link>
        </v-row>
      </div>
    </v-card>
    <v-snackbar v-model="isNotification" timeout="-1" top elevation="0" color="transparent" width="100%"
      max-width="1200px" class="ma-0 pa-0" content-class="ma-0 pa-0 rounded" style="z-index: 2100;">
      <div class="ma-0 mb-0">
        <v-alert width="100%" class="my-1 py-0 white--text" border="left" colored-border style="background: white;"
          :type="targetNotification.type" elevation="2" prominent>
          <v-btn color="black" style="position: absolute; right: 10px; top: 5px; z-index: 2110;" icon small dense
            @click="deleteNotification()">
            <v-icon>{{ svgClose }}</v-icon>
          </v-btn>
          <v-list-item class="ma-0 mb-0">
            <v-list-item-content class="black--text ma-0 mb-0">
              <v-list-item-subtitle class="ma-0 mb-0 grey--text" style="white-space: normal;">{{ targetNotification.title
              }}</v-list-item-subtitle>
              <v-col v-html="targetNotification.text" class="ma-0 pa-1"></v-col>
            </v-list-item-content>
          </v-list-item>
        </v-alert>
      </div>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import { mdiClose, mdiEye, mdiEyeOff, mdiLockOpen } from "@mdi/js";
export default {
  name: "Login",
  data: () => ({
    svgClose: mdiClose,
    svgEye: mdiEye,
    svgEyeOff: mdiEyeOff,
    svgLockOpen: mdiLockOpen,
    loading: false,
    showPassword: false,
    isNoMatch: false,
    isAccountLocked: false,
    loadedList: [],
    completeList: ["login"],
    types: ["navi", "object", "image", "pdf", "sound", "movie", "url"],
    emailRules: [
      v => !!v || "メールアドレスは必須項目です。",
      v => (v && v.length <= 255) || "メールアドレスは255文字以内で入力してください。",
      v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "メールアドレスの形式が正しくありません。",
    ],
    passwordRules: [
      v => !!v || "パスワードは必須項目です。",
      v => (v && v.length <= 32) || "パスワードは32文字以内で入力してください。",
      v => (v && v.length >= 8) || "パスワードは8文字以上で入力してください。",
    ],
    model: {
      email: "",
      password: "",
    },
    systemNotification: [],
    targetNotification: {
      id: "",
      status: "",
      type: "info",
      title: "",
      text: "",
    },
    targetIndex: 0,
    isNotification: false,
  }),

  head() {
    return {
      meta: [
        { description: "NarekomuVRはMR技術により空間ナビゲーションを行うことはできます。本ポータルサイトではコンテンツのアップロード、管理、確認が行えます。" },
      ],
    };
  },

  mounted() {
    //labelと自動入力がかぶるのを避ける
    let labels = document.getElementsByTagName("label");
    labels[0].classList.add("v-label--active");
    labels[1].classList.add("v-label--active");
    labels[0].classList.add("back-white");
    labels[1].classList.add("back-white");
    setTimeout(function () {
      labels[0].style = "background: white; left: 0px; right: auto; position: absolute;";
      labels[1].style = "background: white; left: 0px; right: auto; position: absolute;";
    }, 300);
  },

  methods: {
    async login() {
      if (this.$refs.loginForm.validate()) {
        this.loading = true;

        try {
          const response = await axios.post(`${process.env.VUE_APP_URL}login`, {
            UserId: this.model.email,
            Password: this.model.password,
          }, {
            headers: {
              "X-Api-Key": process.env.VUE_APP_KEY,
              "Content-Type": "application/json",
            },
          });

          if (response.data) {
            const login = response.data.results;

            await this.$store.dispatch("setLoginInfo", login);
            this.loadedList.push("login");

            await this.$store.dispatch("setUserInfo", login);
            this.loadedList.push("user");

            this.$router.push({
              path: "backuri" in this.$route.query && this.$route.query.backuri.match(/^\//) ? this.$route.query.backuri : '/',
            });
          }
        } catch (error) {
          this.loading = false;

          console.log(error.response.status);
          if (error.response.status == 429) {
            this.isAccountLocked = true;
            this.isNoMatch = false;
          } else {
            this.isNoMatch = true;
            this.isAccountLocked = false;
          }
        }
      }
    },
  },
};
</script>

